<template>
	<div>
		<DottedSpinner :color="'#0D7542'"></DottedSpinner>
		<h3 style="color:#0D7542;text-align: center">Creating New Account...</h3>
	</div>
</template>

<script>
	import {VueController} from '@/service/support/vuex-controller';
    import stripeService from "@/service/stripe-service";
    import DottedSpinner from "../components/DottedSpinner";


	export default VueController("WelcomeNewAccount", {
	    components: {
            DottedSpinner
        },
		state: [
			"cognito",
            "organization",
            "user",
            "newOrganizationTier",
            "newOrganizationName",
            "stripeDiscount",
            "subscriptionPlanOptions",
            "modals",
            "validPromoCode"
		],
        data() {
	        return {

                promoCodeFailed: false,
                promoCodeValid: false,
                promoCode: "",
                promoDetails: "",
                stripeInterval: ""
            };
        },
        mounted() {
	        let selectedPlan = this.$route.query.plan;
	        let selectedDiscount = this.$route.query.discount;

            if (!selectedPlan) {
                let planCookie = this.getCookie("plan");
                if (planCookie) {
                    selectedPlan = planCookie;
                }
            }

            if (!selectedDiscount) {
	            let billingCookie = this.getCookie("billing");
	            if (billingCookie) {
		            selectedDiscount = billingCookie === "annual";
	            }
            }

	        if (!selectedPlan || selectedPlan === "undefined") {
	            selectedPlan = "professional";
            }

	        if (!selectedDiscount || selectedDiscount === "undefined") {
		        selectedDiscount = false;
	        }

            stripeService.jumpToNewAccountCheckoutPage(selectedPlan, selectedDiscount,null);
        },
        computed: {
	        promoDescription(){
	          if(this.promoCodeDetails && this.promoCodeDetails.coupon && this.promoCodeDetails.coupon.name){
                  return this.promoCodeDetails.coupon.name;
              }
            },
            planDescription() { //TODO: Create shared computed in local-state
                let lowerCase = this.newOrganizationTier.toLowerCase();
                let tierDescription = '';
                switch (lowerCase) {
                    case "starter":
                        tierDescription = "Basic Plan, Limited to 1 User, Maximum of 50 client portals, 500GB Storage";
                        break;
                    case "large firm":
                  case "advanced":
                        tierDescription = "Large Business Plan, Unlimited Users, Maximum of 10,000 client portals, 10TB Storage";
                        break;
                    case "enterprise":
                        tierDescription = "Corporate Plan, Unlimited Users, Unlimited Client Portals, Unlimited Data";
                        break;
                    default:
                        tierDescription = "Professional Plan, Unlimited Users, Maximum of 1,000 client portals, 1TB Storage";
                        break;
                }
                return tierDescription;
            },
            planPriceEstimate() {
                let estimate = "";
                let planTier = this.planTitle(this.newOrganizationTier);
                if (this.subscriptionPlanOptions) {
                    let planDetails = this.subscriptionPlanOptions.find(plan => plan.title === planTier);
                    if (planDetails) {
                        let planPrice = planDetails.price[this.stripeInterval.toLowerCase() === "month" ? "monthly" : "yearly"];
                        if (this.stripeInterval.toLowerCase() !== "month") {
                            planPrice = "$" + (Number(planPrice.replace('$', '')) * 12);
                        }
                        estimate = `You have elected the ${planTier} plan to be billed at ${planPrice}.00 on a ${this.stripeInterval}ly basis.`;
                    }
                }

                return estimate;
            }
        },

        methods: {
            planTitle(planId) {
              let planName = "Team";
              switch (planId.toLowerCase()) {
                case "starter":
                	planName = "Starter";
                	break;
                case "professional":
                  planName = "Professional"
                  break;
                case "large firm":
                case "advanced":
                  planName = "Large Firm";
                  break;
                case "enterprise":
                  planName = "Enterprise";
                  break;
              }
              return planName;
            },
            onPaymentProcessed() {
                let self = this;
                stripeService.query("getOrgById", {org_id: self.user.administratorOf}, "organization", {vuexCache: false}).then(refreshOrg => {
                    self.$router.push("/vaults?message=Thank you for joining Zapa.");
                });
            },
            getCookie(cname) {
                var name = cname + "=";
                var decodedCookie = decodeURIComponent(document.cookie);
                var ca = decodedCookie.split(';');
                for(var i = 0; i <ca.length; i++) {
                    var c = ca[i];
                    while (c.charAt(0) === ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) === 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            }
        }
	});
</script>

<style lang="scss" scoped>
	$color: #0D7542;
    $darkerColor: #074024;

	h1 {
		color: $color;
	}

    h2 {
        color: $color;
    }

    h4 {
        color: #676767;
    }

    h5, span {
        color: gray;
    }

    button.btn.mt-4.v-btn.v-btn--contained.v-btn--disabled.theme--light {
        background-color: #d5b9e7 !important;
        color: #FAF5E3 !important;
    }

    .note-text {
        line-height: 1 !important;
        font-size: 12px;
    }
</style>
